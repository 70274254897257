<template>
  <div class="pusher"> 
    <h4></h4>
    <h1>Pusher Test</h1>
    <p>
      Publish an event to channel <code>my-channel</code>
      with event name <code>my-event</code>; it will appear below:
    </p>
  
    <ul>
      <li>Lista de Mensajes</li>
      <li v-for="message in messages" v-bind:key="message.id">
        {{message}}
      </li>
    </ul>
  </div>
</template>
<script src="https://js.pusher.com/7.2/pusher.min.js"></script>
<script>
// Enable pusher logging - don't include this in production
Pusher.logToConsole = true;

var pusher = new Pusher('c7fd99b5022d52b98e59', {
  cluster: 'mt1'
});

var channel = pusher.subscribe('channel_sidfm');
channel.bind('my-event', function(data) {
  console.log('pusher ' + data);
  this.messages.push(JSON.stringify(data));
});
</script>
<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
// import ProductForm from "@/components/ProductForm";
//import baseApi from '@/services/baseApi';

export default {
  name: 'pusher',
  computed:{
      ...mapState(['purchases','currencies','currentUser','suppliers'])    
  },
  components: {
      //FieldSearch,
      // ProductForm
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]), 
    async getBreadcrumps(){  }   
   
  },
  created(){
    this.changeBreadcrumbs({
        page:"pusher",
        title:"Lista de Mensajes",
        subtitle:"primary",
        description:"En esta lista encontrará todas las compras dados de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps(this.sword)    
  },
  data(){
     return{
      messages: [],
      dialog:false,
      attribute: {sword: 'All',limit: 500},
      sword:"",
      sfecha:new Date().toISOString().substr(0,10),
      rows: [],
      editRow:null,
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        {
          text: 'Descripción',
          align: 'start',
          sortable: false,
          value: 'id',
        },        
        { text: 'Costo Envío', value: 'shipping_cost' },
        { text: 'Otro Costo', value: 'other_cost' },
        { text: 'Costo Aduana', value: 'aduana_cost' },
        { text: 'Comision', value: 'comision' },
        { text: 'Tipo de Cambio', value: 'currencytipocambio' },
        { text: 'Costo Productos', value: 'cost_product' },
        { text: 'IVA', value: 'iva' },
        { text: 'Costo Total', value: 'totalpurchase' }        
      ]
    }
  }
}
</script>
 